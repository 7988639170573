import React, { useState, useEffect, useCallback } from "react";
import Axios from "axios";
import Dropdown from "react-bootstrap/Dropdown";
import io from "socket.io-client";

import Timer from "react-compound-timer";
import InputMask from "react-input-mask";

import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { withRouter } from "react-router";
import Notifier from "react-desktop-notifications";

import { FormControlLabel, Checkbox } from "@material-ui/core";

import apiService from "../../services/api";

import Details from "./details";

import NotificationRing from "../../audio/notification-large.mp3";
import { Container, CampaignBannerWrapper } from "./styles";
import Contratos from "../../components/contratos";

import { Link } from "react-router-dom";
import isElectron from "is-electron";
import { Modal as CustomModal } from "../../components/Modal";
import CurrencyInput from "react-currency-input";
import Api from "../../services/api";

const Pedidos = () => {
  let mp3 = new Audio(NotificationRing);

  const { addToast } = useToasts();

  const hasCampaignBanner = true;

  const campaignBannerAddress = "https://forms.gle/WPiLq2xr7Nz8pJUU8";

  const [loadingPrincipal, setLoadingPrincipal] = useState(false);
  const [orders, setOrders] = useState([]);
  const [_, setPedidosPendentes] = useState(0);
  const [audio] = useState(mp3);
  const [searchParameter, setSearchParameter] = useState("");
  const [printerError, setPrinterError] = useState(false);
  const [orderSelected, setOrderSelected] = useState({});
  const [orderToUpdate, setOrderToUpdate] = useState({});
  const [orderToAdd, setOrderToAdd] = useState({});
  const [socketConnectionStatus, setSocketConnectionStatus] = useState({});
  const [pagamentoOnline, setPagamentoOnline] = useState();
  const [modal, setModal] = useState(false);
  const [avisoExibir, setAvisoExibir] = useState(false);

  const [selectedPrinter, setSelectedPrinter] = useState("");
  const [printers, setPrinters] = useState([]);

  const [pedidoAutoAceiteImprimir, setPedidoAutoAceiteImprimir] = useState();
  const [autoAceiteImprimir, setAutoAceiteImprimir] = useState(false);

  // const [isLoading, setIsLoading] = useState(false);
  // const [isInvalidPhone, setIsInvalidPhone] = useState(false);

  //External delivery states

  // const [cities, setCities] = useState([]);
  // const [externalDeliveryActive, setExternalDeliveryActive] = useState(false);

  // const [externalDeliveryCity, setExternalDeliveryCity] = useState([]);
  // const [externalDeliveryArea, setExternalDeliveryArea] = useState("");
  // const [externalDeliveryTax, setExternalDeliveryTax] = useState(0);
  // const [externalDeliveryNumber, setExternalDeliveryNumber] = useState("");
  // const [externalDeliveryStreet, setExternalDeliveryStreet] = useState("");
  // const [externalDeliveryComplement, setExternalDeliveryComplement] =
  //   useState("");
  // const [externalDeliveryClientName, setExternalDeliveryClientName] =
  //   useState("");
  // const [externalDeliveryClientContact, setExternalDeliveryClientContact] =
  //   useState("");
  // const [externalDeliveryOrderSource, setExternalDeliveryOrderSource] =
  //   useState("");
  // const [externalDeliveryPaymentType, setExternalDeliveryPaymentType] =
  //   useState("");
  // const [externalDeliveryOrderValue, setExternalDeliveryOrderValue] =
  //   useState("");

  // const [isVisible, setIsVisible] = useState(false);

  const api = Axios.create({
    baseURL: Api.defaults.baseURL,
    headers: {
      Authorization: apiService.defaults.headers["Authorization"],
    },
  });

  if (isElectron()) {
    console.log("Electron aww yeahhh !");
  } else {
    console.log("Running in other platform as a normal browser");
  }

  function fecharModal() {
    setModal(false);
  }

  async function getAvisoAlterar() {
    try {
      await apiService.get("estabelecimento/alteraAviso");
    } catch {}
  }
  useEffect(() => {
    getConnectionAndOrders();
    getStoreInfo();
    getDefaultPrinter();
    connectToSocket();
    getConfigs();
    playMp3();
    getAcceptanceStatus();

    Notification.requestPermission(/* opcional: callback */);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getConfigs() {
    const autoAceiteImprimir = localStorage.getItem("autoAceiteImprimir");
    if (autoAceiteImprimir === "true") {
      setAutoAceiteImprimir(true);
    }
  }
  async function getDefaultPrinter() {
    if (localStorage.getItem("impressora")) {
      setSelectedPrinter(localStorage.getItem("impressora"));
    }
  }

  async function getPrinters() {
    try {
      const response = await Axios.get("http://127.0.0.1:65517/impressoras");
      const printersResponse = response.data.impressoras;
      const printersArray = [];

      for (let index = 0; index < printersResponse.length; index++) {
        printersArray.push({ nome: printersResponse[index] });
      }

      setPrinters(printersArray);
    } catch (error) {
      setPrinterError(true);
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Módulo da impressora não encontrado ",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  function notificacaoDesktop(texto) {
    var notification = new Notification("Aiboo", {
      //icon: 'http://i.stack.imgur.com/dmHl0.png',
      body: texto,
    });
    console.log(notification);
  }

  function choosePrinter(printerName) {
    setSelectedPrinter(printerName);
    localStorage.setItem("impressora", printerName);
  }

  const PrintersComponent = () => {
    return (
      <>
        <select
          className="form-control"
          value={selectedPrinter}
          onChange={(e) => choosePrinter(e.target.value)}
        >
          {printers.map((item) => {
            return (
              <option key={item.nome} value={item.nome}>
                {item.nome}
              </option>
            );
          })}
        </select>
      </>
    );
  };

  async function connectToSocket() {
    const channel = localStorage.getItem("canal_socket");
    const url = localStorage.getItem("url_socket");
    openConnectionWithSocket(channel, url);
  }

  async function getConnectionAndOrders() {
    setLoadingPrincipal(true);
    const response = await apiService.get("pedidos/listar");
    if (response.data?.status === true) {
      //Tirei a abertura de conexão para/com o socket daqui.
      setOrders(response.data.pedidos);
      setLoadingPrincipal(false);
    }
  }

  async function justGetOrders() {
    //Não sei o que fazia, comentei pq tava gerando lixo.
    /*setLoadingPrincipal(true);
    const response = await Api.get('pedidos/listar');
    if(response.data?.status === true){
      setOrders(response.data.pedidos)
      setLoadingPrincipal(false)
    }*/
  }
  useEffect(() => {
    orders.forEach((e) => {
      if (e.hash_pedido === orderSelected?.hash_pedido) {
        e.classpedidoatraso = " pedido-novo-atraso";
        e.classpedido = " pedido-selecionado";
        setOrderSelected(e);
      } else {
        e.classpedido = "";
        e.classpedidoatraso = "";
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orders]);

  function getDetailsOrder(id, order) {
    orders.forEach((e) => {
      if (e.hash_pedido === order.hash_pedido) {
        e.classpedidoatraso = " pedido-novo-atraso";
        e.classpedido = " pedido-selecionado";
        setOrderSelected(e);
      } else {
        e.classpedidoatraso = "";
        e.classpedido = "";
      }
    });
  }

  useEffect(() => {
    var nOrders = [];
    if (orderToAdd.hash_pedido) {
      notificacaoDesktop("Novo pedido " + orderToAdd.hash_pedido);
      if (autoAceiteImprimir === true) {
        setPedidoAutoAceiteImprimir(orderToAdd);
      }
      nOrders[nOrders.length] = orderToAdd;
      orders.forEach((o) => {
        nOrders[nOrders.length] = o;
      });
      setOrders(nOrders);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderToAdd]);

  function getThisNewOrder(data) {
    apiService.get("pedidos/listar/" + data.id_pedido).then((response) => {
      if (response.data.status === true) {
        setOrderToAdd(response.data.pedidos[0]);
        //orders.unshift(response.data.pedidos[0])
        // playRingMP3();
        //getConnectionAndOrders()
      }
    });
  }

  const [estabilishmentConfig, setEstabilishmentConfig] = useState({
    entrega_minimo: "",
    entrega_maximo: "",
    fechado: false,
    nome: "",
  });

  async function updateDeliveryTime(min, max) {
    const response = await apiService.post("estabelecimento/mudaPrevisao", {
      entrega_minimo: min,
      entrega_maximo: max,
    });
    if (response.data.status !== undefined && response.data.status === true) {
      addToast(response.data.mensagem, {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } else {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: response.data.mensagem,
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    getStoreInfo();
  }

  async function getStoreInfo() {
    const response = await apiService.get("estabelecimento/getLoja");
    setEstabilishmentConfig({
      fechado: response.data.estabelecimento["fechado"],
      nome: response.data.estabelecimento["nome"],
      entrega_minimo: response.data.estabelecimento["entrega_minimo"],
      entrega_maximo: response.data.estabelecimento["entrega_maximo"],
    });
    // setExternalDeliveryActive(response.data.estabelecimento.has_external_delivery);
    //setPagamentoOnline(response.data.estabelecimento['zoop_status'],)

    if (response.data.estabelecimento["aviso"] === "S") {
      setModal(true);
    }
  }

  const [acceptsOnlinePayment, setAcceptsOnlinePayment] = useState(true);
  function getAcceptanceStatus() {
    apiService.get("pagamentoonline/getAcceptanceStatus").then((rps) => {
      if (rps.data.status === true) {
        var data = rps.data.acceptsOnline;
        setAcceptsOnlinePayment(data);
      }
    });
  }

  async function acceptNewOrder() {
    stopRingMp3();
  }

  useEffect(() => {
    var nOrders = [];
    orders.forEach((o) => {
      if (o.hash_pedido === orderToUpdate?.hash_pedido) {
        nOrders[nOrders.length] = orderToUpdate;
      } else {
        nOrders[nOrders.length] = o;
      }
    });
    setOrders(nOrders);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderToUpdate]);

  function updateOrder(data) {
    apiService.get("pedidos/listar/" + data.id_pedido).then((response) => {
      if (response.data.status === true) {
        setOrderToUpdate(response.data.pedidos[0]);
        //console.log(orders);
        //orders.unshift(response.data.pedidos[0]);
      }
    });
    //getConnectionAndOrders()
  }

  function openConnectionWithSocket(channel, url) {
    const socket = io(url, {
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
    });
    socket.on("connect", (data) => {
      console.log("Socket conectado");
      socket.emit("room", channel);
      setSocketConnectionStatus("conexaoOk");
    });
    socket.on("disconnect", (data) => {
      console.log("Socket DESCONECTADO");
      setSocketConnectionStatus("conexaoNok");
      addToast("Perda de conexão com o servidor", {
        appearance: "error",
        autoDismiss: true,
        autoDismissTimeout: 5000,
      });
      //Notificar
    });
    socket.on("pedido", (data) => {
      if (data.tipo === "novo") {
        getThisNewOrder(data);
      } else if (data.tipo === "atualiza") {
        updateOrder(data);
      } else if (data.tipo === "f5pagina") {
        getConnectionAndOrders();
      } else if (data.tipo === "mensagem") {
        alert(data.mensagem);
      }
    });
  }

  function playMp3() {
    var contagemPedidos = 0;
    orders.forEach((e) => {
      if (e.status === "PP") {
        contagemPedidos++;
      }
    });

    if (contagemPedidos > 0) {
      audio.play();
    } else {
      audio.pause();
    }
  }

  function stopRingMp3() {
    audio.pause();
  }
  // const toggleModalVisibility = useCallback(() => {
  //   setIsVisible((prevState) => !prevState);
  // }, []);

  // const ExternalDeliveryButton = () => (
  //   <button
  //     className="btn btn-sm btn-secondary d-flex justify-content-center mr-2"
  //     style={{
  //       height: 30,
  //       paddingLeft: 10,
  //       paddingRight: 10,
  //       textAlign: "center",
  //     }}
  //     onClick={() => {
  //       toggleModalVisibility();
  //       ExternalDeliveryFormModal();
  //     }}
  //   >
  //     <i className="fas fa-motorcycle mx-2" />
  //     <label>Entrega externa</label>
  //   </button>
  // );

  // async function getCities() {
  //   try {
  //     const response = await api.get(`estabelecimento/cidadesEBairros`);
  //     setCities(response.data.cities);
  //   } catch (error) {
  //     addToast("Não foi possível carregar as listas de cidades e bairos", {
  //       appearance: "error",
  //       autoDismiss: true,
  //       autoDismissTimeout: 2000,
  //     });
  //   }
  // }

  // const removeNonNumericCharacters = (item) => {
  //   const formattedItem = item.replace(/\D/g, "");
  //   return formattedItem;
  // };

  // const removeCurrencySign = (orderValue) => {
  //   const formattedValue = Number(orderValue.replace(/[^0-9\.-]+/g, ""));
  //   return formattedValue;
  // };

  // const resetFormStatesData = () => {
  //   setExternalDeliveryCity([]);
  //   setExternalDeliveryArea("");
  //   setExternalDeliveryTax(0);
  //   setExternalDeliveryNumber("");
  //   setExternalDeliveryStreet("");
  //   setExternalDeliveryComplement("");
  //   setExternalDeliveryClientName("");
  //   setExternalDeliveryClientContact("");
  //   setExternalDeliveryOrderSource("");
  //   setExternalDeliveryPaymentType("");
  //   setExternalDeliveryOrderValue("");
  //   toggleModalVisibility();
  //   setIsInvalidPhone(false);
  // };

  // const HandleSubmitExternalDelivery = async () => {
  //   setIsLoading(true);

  //   const establishmentId = localStorage.getItem("id_estabelecimento");

  //   const formattedClientContact = removeNonNumericCharacters(
  //     externalDeliveryClientContact
  //   );
  //   const formattedOrderValue = removeCurrencySign(externalDeliveryOrderValue);

  //   const cityName =
  //     externalDeliveryCity.length && JSON.parse(externalDeliveryCity).name;

  //   const stateName =
  //     externalDeliveryCity.length && JSON.parse(externalDeliveryCity).uf;

  //   const selectedArea = externalDeliveryArea?.selectedArea;
  //   const selectedAreaName = selectedArea && JSON.parse(selectedArea).name;

  //   if (formattedClientContact.length < 11) {
  //     setIsInvalidPhone(true);
  //     setIsLoading(false);
  //     return;
  //   }

  //   setIsInvalidPhone(false);

  //   const externalDeliveryFormData = new FormData();

  //   externalDeliveryFormData.append("merchant_id", establishmentId);
  //   externalDeliveryFormData.append("client_name", externalDeliveryClientName);
  //   externalDeliveryFormData.append("client_contact", formattedClientContact);
  //   externalDeliveryFormData.append("origin", externalDeliveryOrderSource);
  //   externalDeliveryFormData.append(
  //     "payment_type",
  //     externalDeliveryPaymentType
  //   );
  //   externalDeliveryFormData.append("total_order", formattedOrderValue);
  //   externalDeliveryFormData.append("city_name", cityName);
  //   externalDeliveryFormData.append("uf", stateName);
  //   externalDeliveryFormData.append("number", externalDeliveryNumber);
  //   externalDeliveryFormData.append("area_name", selectedAreaName);
  //   externalDeliveryFormData.append("street_name", externalDeliveryStreet);
  //   externalDeliveryFormData.append("delivery_charge", externalDeliveryTax);

  //   try {
  //     await api.post(
  //       `Pedidos/createExternalTask`,

  //       externalDeliveryFormData
  //     );
  //     setIsLoading(false);
  //     setIsInvalidPhone(false);
  //     resetFormStatesData();
  //     addToast("O serviço de entrega externa foi solicitado!", {
  //       appearance: "success",
  //       autoDismiss: true,
  //       autoDismissTimeout: 2000,
  //     });
  //   } catch (error) {
  //     setIsLoading(false);
  //     addToast("Não foi possível chamar uma entrega externa", {
  //       appearance: "error",
  //       autoDismiss: true,
  //       autoDismissTimeout: 2000,
  //     });
  //   }
  // };

  // const ExternalDeliveryFormModal = () => {
  //   return (
  //     <CustomModal
  //       toggleModalVisibility={toggleModalVisibility}
  //       visible={isVisible}
  //       showCloseButton={true}
  //     >
  //       <div
  //         className="rounded"
  //         style={{
  //           padding: 20,
  //           paddingRight: 0,
  //           paddingBottom: 10,
  //           backgroundColor: "#FFFFFF",
  //         }}
  //       >
  //         <div
  //           className="d-flex flex-column "
  //           style={{
  //             height: "80vh",
  //             width: "70vw",
  //             padding: 20,

  //             backgroundColor: "#FFFFFF",
  //             overflowY: "scroll",
  //           }}
  //         >
  //           <div className="border-bottom mb-5">
  //             <h5>Entrega externa</h5>
  //             <p>
  //               Chame um entregador para fazer uma entrega de outra plataforma
  //             </p>
  //           </div>

  //           <div>
  //             <form className="w-100" onSubmit={HandleSubmitExternalDelivery}>
  //               <div className="d-flex flex-row justify-content-between ">
  //                 <div className="col md-7 p-0">
  //                   <div className="form-group ">
  //                     <label>
  //                       Cidade<span className="text-primary">*</span>
  //                     </label>
  //                     <select
  //                       className="form-control"
  //                       value={externalDeliveryCity}
  //                       onChange={(item) => {
  //                         if (item.target.value === "") {
  //                           setExternalDeliveryCity("");
  //                           setExternalDeliveryArea({});
  //                           return;
  //                         }
  //                         const formattedCity = JSON.parse(item.target.value);
  //                         setExternalDeliveryArea({
  //                           selectedArea: "",
  //                           areas: formattedCity.areas,
  //                         });
  //                         setExternalDeliveryCity(item.target.value);
  //                       }}
  //                       style={{ width: 200 }}
  //                       required
  //                     >
  //                       <option value="" selected>
  //                         Selecione
  //                       </option>

  //                       {cities?.map((city) => (
  //                         <option value={JSON.stringify(city)}>
  //                           {city.name}
  //                         </option>
  //                       ))}
  //                     </select>
  //                   </div>

  //                   <div className="form-group">
  //                     <label>
  //                       Bairro/área<span className="text-primary">*</span>
  //                     </label>
  //                     <select
  //                       className="form-control"
  //                       value={externalDeliveryArea.selectedArea}
  //                       onChange={(item) => {
  //                         if (item.target.value === "") {
  //                           setExternalDeliveryArea({
  //                             ...externalDeliveryArea,
  //                             selectedArea: "",
  //                           });
  //                           return;
  //                         }
  //                         const formattedArea = JSON.parse(item.target.value);
  //                         setExternalDeliveryArea(
  //                           (prevExternalDeliveryArea) => ({
  //                             ...prevExternalDeliveryArea,
  //                             selectedArea: item.target.value,
  //                           })
  //                         );
  //                         setExternalDeliveryTax(formattedArea?.tax);
  //                       }}
  //                       style={{ width: 200 }}
  //                       required
  //                     >
  //                       <option value="" selected>
  //                         Selecione
  //                       </option>
  //                       {externalDeliveryArea?.areas?.map((area) => (
  //                         <option value={JSON.stringify(area)}>
  //                           {area.name}
  //                         </option>
  //                       ))}
  //                     </select>
  //                     {externalDeliveryArea?.selectedArea &&
  //                       externalDeliveryTax <= 0 && (
  //                         <small className="text-primary">
  //                           Não foi encontrada uma taxa de entrega para este
  //                           bairro.
  //                         </small>
  //                       )}
  //                   </div>

  //                   <div className="form-group ">
  //                     <label>
  //                       Número<span className="text-primary">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       value={externalDeliveryNumber}
  //                       onChange={(item) =>
  //                         setExternalDeliveryNumber(item.target.value)
  //                       }
  //                       className="form-control"
  //                       style={{ width: 150 }}
  //                       required
  //                     />
  //                   </div>

  //                   <div className="form-group ">
  //                     <label>
  //                       Logradouro<span className="text-primary">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       value={externalDeliveryStreet}
  //                       onChange={(item) =>
  //                         setExternalDeliveryStreet(item.target.value)
  //                       }
  //                       className="form-control"
  //                       style={{ width: 400 }}
  //                       required
  //                     />
  //                   </div>

  //                   <div className="form-group ">
  //                     <label>
  //                       Complemento<span className="text-primary">*</span>
  //                     </label>
  //                     <input
  //                       type="text"
  //                       value={externalDeliveryComplement}
  //                       onChange={(item) =>
  //                         setExternalDeliveryComplement(item.target.value)
  //                       }
  //                       className="form-control"
  //                       style={{ width: 400 }}
  //                       required
  //                     />
  //                   </div>
  //                 </div>

  //                 {externalDeliveryTax > 0 && (
  //                   <div
  //                     className=" rounded"
  //                     style={{
  //                       backgroundColor: "#E3E8EC",
  //                       height: 70,
  //                       width: 300,
  //                       display: "flex",
  //                       justifyContent: "center",
  //                       alignItems: "center",
  //                     }}
  //                   >
  //                     <label style={{ fontSize: 18 }}>
  //                       Taxa de entrega
  //                       <span
  //                         style={{
  //                           fontSize: 20,
  //                           fontWeight: 700,
  //                           marginLeft: 15,
  //                         }}
  //                       >
  //                         R$ {externalDeliveryTax}
  //                       </span>
  //                     </label>
  //                   </div>
  //                 )}
  //               </div>
  //               <div>
  //                 <div className="border-bottom mb-5">
  //                   <h5>Dados do cliente e pedido</h5>
  //                 </div>

  //                 <div className="form-group ">
  //                   <label>
  //                     Nome do cliente<span className="text-primary">*</span>
  //                   </label>
  //                   <input
  //                     type="text"
  //                     value={externalDeliveryClientName}
  //                     onChange={(item) =>
  //                       setExternalDeliveryClientName(item.target.value)
  //                     }
  //                     className="form-control"
  //                     style={{ width: 400 }}
  //                     required
  //                   />
  //                 </div>

  //                 <div className="form-group ">
  //                   <label>
  //                     Contato do cliente<span className="text-primary">*</span>
  //                   </label>
  //                   <InputMask
  //                     mask="(99) 99999-9999"
  //                     maskChar={null}
  //                     value={externalDeliveryClientContact}
  //                     onChange={(item) =>
  //                       setExternalDeliveryClientContact(item.target.value)
  //                     }
  //                     type="tel"
  //                     minLength={20}
  //                     className="form-control"
  //                     placeholder="(00) 00000-0000"
  //                     style={{ width: 200 }}
  //                     required
  //                   />
  //                   {isInvalidPhone && (
  //                     <small className="text-primary">
  //                       O telefone inserido é inválido.
  //                     </small>
  //                   )}
  //                 </div>

  //                 <div className="form-group">
  //                   <label>
  //                     Origem do pedido<span className="text-primary">*</span>
  //                   </label>
  //                   <select
  //                     className="form-control"
  //                     value={externalDeliveryOrderSource}
  //                     onChange={(item) =>
  //                       setExternalDeliveryOrderSource(item.target.value)
  //                     }
  //                     style={{ width: 200 }}
  //                     required
  //                   >
  //                     <option value="" selected>
  //                       Selecione
  //                     </option>
  //                     <option value="establishment-app">App próprio</option>
  //                     <option value="ifood">iFood</option>
  //                     <option value="instagram">Instagram</option>
  //                     <option value="quero-delivery">Quero delivery</option>
  //                     <option value="phone">Telefone</option>
  //                     <option value="whatsapp">Whatsapp</option>
  //                     <option value="exception">Outro</option>
  //                   </select>
  //                 </div>

  //                 <div className="form-group">
  //                   <label>
  //                     Método de pagamento<span className="text-primary">*</span>
  //                   </label>
  //                   <select
  //                     className="form-control"
  //                     value={externalDeliveryPaymentType}
  //                     onChange={(item) =>
  //                       setExternalDeliveryPaymentType(item.target.value)
  //                     }
  //                     style={{ width: 200 }}
  //                     required
  //                   >
  //                     <option value="" selected>
  //                       Selecione
  //                     </option>
  //                     <option value="pyr">Cartão na entrega</option>
  //                     <option value="cod">Dinheiro na entrega</option>
  //                     <option value="oln">Pagamento online</option>
  //                   </select>
  //                 </div>

  //                 <div className="form-group">
  //                   <label>
  //                     Valor do pedido<span className="text-primary">*</span>
  //                   </label>
  //                   <CurrencyInput
  //                     value={externalDeliveryOrderValue}
  //                     onChangeEvent={(item) =>
  //                       setExternalDeliveryOrderValue(item.target.value)
  //                     }
  //                     className="form-control"
  //                     style={{ width: 200 }}
  //                     placeholder="R$ 0,00"
  //                     prefix="R$"
  //                     required
  //                   />
  //                 </div>
  //               </div>
  //               <div className="w-100 d-flex justify-content-end mt-5">
  //                 <button
  //                   type="button"
  //                   disabled={isLoading}
  //                   className="btn btn-sm btn-primary mr-3"
  //                   onClick={() => {
  //                     resetFormStatesData();
  //                   }}
  //                 >
  //                   Cancelar
  //                 </button>

  //                 <button
  //                   type="submit"
  //                   className="btn btn-sm btn- btn-success"
  //                   style={{ minWidth: 120 }}
  //                   disabled={
  //                     externalDeliveryArea?.selectedArea &&
  //                     externalDeliveryTax <= 0
  //                   }
  //                 >
  //                   {isLoading ? (
  //                     <div className="spinner spinner-center"></div>
  //                   ) : (
  //                     "Chamar entregador"
  //                   )}
  //                 </button>
  //               </div>
  //             </form>
  //           </div>
  //         </div>
  //       </div>
  //     </CustomModal>
  //   );
  // };

  const DeliveryTime = () => {
    return (
      <>
        <Dropdown>
          <Dropdown.Toggle
            className="btn btn-sm btn-secondary dropdown-toggle"
            style={{ height: 30, paddingLeft: 15, paddingRight: 15 }}
          >
            <i className="far fa-clock" />
            {estabilishmentConfig["entrega_minimo"]} -{" "}
            {estabilishmentConfig["entrega_maximo"]} min.
          </Dropdown.Toggle>

          <Dropdown.Menu className="dropdown-menu">
            <div
              onClick={() => updateDeliveryTime(15, 30)}
              className="dropdown-item"
            >
              15 - 30 min
            </div>
            <div
              onClick={() => updateDeliveryTime(20, 40)}
              className="dropdown-item"
            >
              20 - 40 min
            </div>
            <div
              onClick={() => updateDeliveryTime(40, 60)}
              className="dropdown-item"
            >
              40 - 60 min
            </div>
            <div
              onClick={() => updateDeliveryTime(60, 90)}
              className="dropdown-item"
            >
              60 - 90 min
            </div>
            <div
              onClick={() => updateDeliveryTime(90, 120)}
              className="dropdown-item"
            >
              90 - 120 min
            </div>
          </Dropdown.Menu>
        </Dropdown>
      </>
    );
  };

  // Modal Actions
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  function handleShow() {
    getPrinters();
    if (printerError) {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Erro no módulo de impressão",
        showCloseButton: true,
        showCancelButton: false,
      });
    } else {
      setShow(true);
    }
  }

  function NewNotification() {
    Notifier.start(
      "Aiboo Parceiro",
      "Chegou um novo pedido!",
      "http://localhost:3000/#/pedidos"
    );
  }

  const LoadingTheme = () => {
    if (loadingPrincipal) {
      return (
        <div className="spinner spinner-primary spinner-center mt-10"></div>
      );
    }
    return (
      <>
        <p onClick={() => NewNotification()} className="text-center p-10">
          Não há nenhum pedido pendente no momento...
        </p>
        <center>
          <img
            alt="sem pedidos pendentes"
            src="assets/media/pedidos-img.png"
            style={{ width: "140px", textAlign: "center" }}
          ></img>
        </center>
      </>
    );
  };

  const OrdersComponent = () => {
    playMp3();

    let aceitos = [];
    let pendentes = [];
    let enviados = [];
    let entregues = [];
    let cancelados = [];

    for (let order of orders) {
      if (
        order.status === "PA" ||
        order.status === "PI" ||
        order.status === "PL" ||
        order.status === "LE"
      ) {
        aceitos.push(order);
      }
      if (order.status === "PP") {
        pendentes.push(order);
        setPedidosPendentes(pendentes.length);
      }
      // if (order.status === 'PI') {
      //   impressos.push(order)
      // }
      if (order.status === "PE" || order.status === "PICKUP_READY") {
        enviados.push(order);
      }
      if (order.status === "PD") {
        entregues.push(order);
      }
      if (order.status === "PC") {
        cancelados.push(order);
      }
    }

    let filterPendentes = pendentes.filter((item) => {
      return (
        item.pedido.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1
      );
    });

    const PedidosPendentes = () => {
      if (pendentes.length > 0) {
        return filterPendentes.map((item, index, arr) => {
          return (
            <React.Fragment key={index}>
              <div className={"pedido-novo " + item?.classpedido}>
                <div
                  onClick={() => getDetailsOrder(item.id_pedido, item)}
                  key={item.hash_pedido}
                  className="row"
                >
                  <div className="col-md-3">
                    <p className="texto-1">{item.hash_pedido}</p>
                    <p className="texto-2">{item.data_criacao}</p>
                  </div>
                  <div className="col-md-6">
                    <p className="texto-1">{item.usuario_nome}</p>
                    {item.compraGlobal === "1" && (
                      <>
                        <p
                          className="cliente-novo"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-user-plus"></i> Cliente novo no
                          App!
                        </p>
                      </>
                    )}
                    {item.compraGlobal !== "1" && item.compraEst === "1" && (
                      <>
                        <p
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          Loja!
                        </p>
                      </>
                    )}
                    <p className="texto-2">
                      {item.valor_total} - {item.estabelecimento_nome}
                      {/* NAO TEM PARAMETRO PRA VER SE TEM DESCONTO */}
                      {/* <span className="label label-sm label-info label-inline ml-2" data-toggle="tooltip" title="Esse desconto será abatadido automaticamente da sua fatura, pois é concedida pelo AIBOO. Cobre do cliente SOMENTE o valor de R$XX.">DESCONTO</span> */}
                    </p>
                  </div>
                  <div className="col-md-3">
                    <p className="texto-andamento timer">
                      <i className="far fa-clock icon-timer" />
                      <Timer
                        initialTime={item.segundos * 1000}
                        formatValue={(value) =>
                          `${value < 10 ? `0${value}` : value}`
                        }
                        direction="backward"
                      >
                        {({
                          start,
                          resume,
                          pause,
                          stop,
                          reset,
                          timerState,
                        }) => (
                          <React.Fragment>
                            <Timer.Minutes />:<Timer.Seconds />
                          </React.Fragment>
                        )}
                      </Timer>
                    </p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        });
      }
      return (
        <>
          <LoadingTheme />
        </>
      );
    };

    let filterAceitos = aceitos.filter((item) => {
      return (
        item.pedido.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1
      );
    });

    const PedidosAceitos = () => {
      if (aceitos.length > 0) {
        return filterAceitos.map((item) => {
          return (
            <React.Fragment key={item.hash_pedido}>
              <div className={"pedido pedido-normal " + item?.classpedido}>
                <div
                  onClick={() => getDetailsOrder(item.id_pedido, item)}
                  className="row"
                >
                  <div className="col-md-3">
                    <p className="texto-1">{item.hash_pedido}</p>
                    <p className="texto-2">{item.data_criacao}</p>
                  </div>
                  <div className="col-md-5">
                    <p className="texto-1">{item.usuario_nome}</p>

                    {item.compraGlobal === "1" && (
                      <>
                        <p
                          className="cliente-novo"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-user-plus"></i> Cliente novo no
                          App!
                        </p>
                      </>
                    )}
                    {item.compraGlobal !== "1" && item.compraEst === "1" && (
                      <>
                        <p
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          Loja!
                        </p>
                      </>
                    )}
                    <p className="texto-2">
                      {item.valor_total} - {item.estabelecimento_nome}{" "}
                    </p>
                  </div>
                  <div className="col-md-4">
                    {item.status === "PA" && (
                      <p className="texto-andamento aceito">ACEITO</p>
                    )}
                    {item.status === "PI" && (
                      <p className="texto-andamento impresso">IMPRESSO</p>
                    )}
                    {item.status === "PL" &&
                      item.externalDeliveryStatus == "SEARCHING_DELIVERY" && (
                        <p className="texto-andamento aceito">
                          <i class="fas fa-motorcycle"></i> BUSCANDO MOTOBOY
                        </p>
                      )}

                    {item.status === "LE" &&
                      item.externalDeliveryStatus == "WAITING_COLLECT" && (
                        <p className="texto-andamento aceito">
                          <i class="fas fa-motorcycle"></i> MOTOBOY A CAMINHO
                        </p>
                      )}
                    {/* <Spinner animation="border" variant="primary" /> */}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        });
      }
      return (
        <>
          <p className="text-center p-10">Não há nenhum pedido aceito...</p>
        </>
      );
    };

    let filterEnviados = enviados.filter((item) => {
      return (
        item.pedido.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1
      );
    });

    const PedidosEnviados = () => {
      if (enviados.length > 0) {
        return filterEnviados.map((item) => {
          return (
            <React.Fragment key={item.hash_pedido}>
              <div
                onClick={() => getDetailsOrder(item.id_pedido, item)}
                className={"pedido pedido-normal " + item?.classpedido}
              >
                <div className="row">
                  <div className="col-md-3">
                    <p className="texto-1">{item.hash_pedido}</p>
                    <p className="texto-2">{item.data_criacao}</p>
                  </div>
                  <div className="col-md-5">
                    <p className="texto-1">{item.usuario_nome}</p>
                    {item.compraGlobal === "1" && (
                      <>
                        <p
                          className="cliente-novo"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-user-plus"></i> Cliente novo no
                          App!
                        </p>
                      </>
                    )}
                    {item.compraGlobal !== "1" && item.compraEst === "1" && (
                      <>
                        <p
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          Loja!
                        </p>
                      </>
                    )}
                    <p className="texto-2">
                      {item.valor_total} - {item.estabelecimento_nome}{" "}
                    </p>
                  </div>
                  <div className="col-md-4">
                    {item.externalDeliveryId !== null && (
                      <>
                        <p className="texto-andamento enviado">
                          <i class="fas fa-motorcycle"></i> ENVIADO
                        </p>
                      </>
                    )}

                    {item.externalDeliveryId == null && (
                      <p className="texto-andamento enviado">
                        {item.pedidoObj.retirada_local
                          ? "PRONTO PARA RETIRADA"
                          : "ENVIADO"}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        });
      }
      return (
        <>
          <p className="text-center p-10">Não há nenhum pedido enviado...</p>
        </>
      );
    };

    let filterEntregues = entregues.filter((item) => {
      return (
        item.pedido.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1
      );
    });

    const PedidosEntregues = () => {
      if (entregues.length > 0) {
        return filterEntregues.map((item) => {
          return (
            <React.Fragment key={item.hash_pedido}>
              <div
                onClick={() => getDetailsOrder(item.id_pedido, item)}
                className={"pedido pedido-normal " + item?.classpedido}
              >
                <div className="row">
                  <div className="col-md-3">
                    <p className="texto-1">{item.hash_pedido}</p>
                    <p className="texto-2">{item.data_criacao}</p>
                  </div>
                  <div className="col-md-5">
                    <p className="texto-1">{item.usuario_nome}</p>
                    {item.compraGlobal === "1" && (
                      <>
                        <p
                          className="cliente-novo"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-user-plus"></i> Cliente novo no
                          App!
                        </p>
                      </>
                    )}
                    {item.compraGlobal !== "1" && item.compraEst === "1" && (
                      <>
                        <p
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          Loja!
                        </p>
                      </>
                    )}
                    <p className="texto-2">
                      {item.valor_total} - {item.estabelecimento_nome}{" "}
                    </p>
                  </div>
                  <div className="col-md-4">
                    {item.externalDeliveryId !== null && (
                      <>
                        <p className="texto-andamento entregue">
                          <i class="fas fa-motorcycle"></i> ENTREGUE
                        </p>
                      </>
                    )}

                    {item.externalDeliveryId == null && (
                      <>
                        <p className="texto-andamento entregue">ENTREGUE</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        });
      }
      return (
        <>
          <p className="text-center p-10">Não há nenhum pedido entregue...</p>
        </>
      );
    };

    let filterCancelados = cancelados.filter((item) => {
      return (
        item.pedido.toLowerCase().indexOf(searchParameter.toLowerCase()) !== -1
      );
    });

    const PedidosCancelados = () => {
      if (cancelados.length > 0) {
        return filterCancelados.map((item) => {
          return (
            <React.Fragment key={item.hash_pedido}>
              <div
                onClick={() => getDetailsOrder(item.id_pedido, item)}
                className={"pedido pedido-normal " + item?.classpedido}
              >
                <div className="row">
                  <div className="col-md-4">
                    <p className="texto-1">{item.hash_pedido}</p>
                    <p className="texto-2">{item.data_criacao}</p>
                  </div>
                  <div className="col-md-6">
                    <p className="texto-1">{item.usuario_nome}</p>
                    {item.compraGlobal === "1" && (
                      <>
                        <p
                          className="cliente-novo"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo no App! Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-user-plus"></i> Cliente novo no
                          App!
                        </p>
                      </>
                    )}
                    {item.compraGlobal !== "1" && item.compraEst === "1" && (
                      <>
                        <p
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          Loja!
                        </p>
                      </>
                    )}
                    <p className="texto-2">
                      {item.valor_total} - {item.estabelecimento_nome}{" "}
                    </p>
                  </div>
                  <div className="col-md-2">
                    <p className="texto-andamento cancelado">CANCELADO</p>
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        });
      }
      return (
        <>
          <p className="text-center p-10">Não há nenhum pedido cancelado...</p>
        </>
      );
    };

    return (
      <>
        <div className="divisor divisor-tabela">
          <span className="text-dark-50 font-weight-bold">Pendente</span>
        </div>
        <PedidosPendentes />
        <div className="divisor divisor-tabela">
          <span className="text-dark-50 font-weight-bold">Aceito/Produção</span>
        </div>
        <PedidosAceitos />
        <div className="divisor divisor-tabela">
          <span className="text-dark-50 font-weight-bold">
            Enviado(s)/Pronto para retirada
          </span>
        </div>
        <PedidosEnviados />
        <div className="divisor divisor-tabela">
          <span className="text-dark-50 font-weight-bold">Entregues</span>
        </div>
        <PedidosEntregues />
        <div className="divisor divisor-tabela">
          <span className="text-dark-50 font-weight-bold">Cancelados</span>
        </div>
        <PedidosCancelados />
      </>
    );
  };

  // useEffect(() => {
  //   if (isVisible) {
  //     getCities();
  //   }
  // }, [isVisible]);

  return (
    <>
      {acceptsOnlinePayment === false && (
        <>
          <div className="container mt-5">
            <div
              className="alert alert-custom alert-light-success container"
              role="alert"
            >
              <div className="alert-icon">
                <i className="fas fa-credit-card" />
              </div>
              <div className="alert-text">
                <div className="row">
                  <div className="col-md-10">
                    <h3>
                      <strong>
                        Aumente suas vendas com o pagamento online
                      </strong>
                    </h3>
                  </div>
                </div>
                A partir de hoje você pode aceitar pagamento online pelo Aiboo.
                Evite maquininhas, e ofereça mais comodidade para seus clientes
                com o pagamento online Aiboo, além de mais prático e rápido, é
                ainda mais seguro e sem contato.
                <br />
                <br />
                Se precisar de ajuda, ligue para o suporte: (27) 4042-1580
                <br />
                <Link
                  className="btn btn-primary mt-1"
                  to="financeiro/cadastro/recebimentos"
                >
                  Saiba mais
                </Link>
              </div>
              <div class="alert-close">
                <button
                  type="button"
                  class="close"
                  data-dismiss="alert"
                  aria-label="Close"
                >
                  <span aria-hidden="true">
                    <i class="ki ki-close"></i>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}

      {
        hasCampaignBanner && (
          <CampaignBannerWrapper>
            <a href={campaignBannerAddress} target="blank">
              <img src="assets/media/black-week-aiboo.webp" alt="Banner promocional" />
            </a>
          </CampaignBannerWrapper>
        )
      }
      <Container
        className="content d-flex flex-column flex-column-fluid"
        id="kt_content"
      >
        <div
          className="subheader espaco-header-pedidos subheader-transparent"
          id="kt_subheader"
        >
          <div className="container d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
            <div className="d-flex align-items-center flex-wrap mr-2">
              <h5 className="text-dark font-weight-bold mt-2 mb-2 mr-5">
                Pedidos
              </h5>
              <div className="subheader-separator subheader-separator-ver mt-2 mb-2 mr-5 bg-gray-200" />
              <div
                className="d-flex align-items-center"
                id="kt_subheader_search"
              >
                <form>
                  <div
                    className="input-group input-group-sm input-group-solid"
                    style={{ maxWidth: "220px" }}
                  >
                    <input
                      onChange={(e) => setSearchParameter(e.target.value)}
                      type="text"
                      className="form-control"
                      id="kt_subheader_search_form"
                      placeholder="Procurar..."
                    />
                    <div className="input-group-append">
                      <span className="input-group-text">
                        <span className="svg-icon">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            width="24px"
                            height="24px"
                            viewBox="0 0 24 24"
                            version="1.1"
                          >
                            <g
                              stroke="none"
                              strokeWidth={1}
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect x={0} y={0} width={24} height={24} />
                              <path
                                d="M14.2928932,16.7071068 C13.9023689,16.3165825 13.9023689,15.6834175 14.2928932,15.2928932 C14.6834175,14.9023689 15.3165825,14.9023689 15.7071068,15.2928932 L19.7071068,19.2928932 C20.0976311,19.6834175 20.0976311,20.3165825 19.7071068,20.7071068 C19.3165825,21.0976311 18.6834175,21.0976311 18.2928932,20.7071068 L14.2928932,16.7071068 Z"
                                fill="#000000"
                                fillRule="nonzero"
                                opacity="0.3"
                              />
                              <path
                                d="M11,16 C13.7614237,16 16,13.7614237 16,11 C16,8.23857625 13.7614237,6 11,6 C8.23857625,6 6,8.23857625 6,11 C6,13.7614237 8.23857625,16 11,16 Z M11,18 C7.13400675,18 4,14.8659932 4,11 C4,7.13400675 7.13400675,4 11,4 C14.8659932,4 18,7.13400675 18,11 C18,14.8659932 14.8659932,18 11,18 Z"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                            </g>
                          </svg>
                        </span>
                      </span>
                    </div>
                  </div>
                </form>
                {/* <div className="form-group align-filtro-pedidos">
                    <div className="checkbox-inline">
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" defaultChecked="checked"/> Em Andamento
                        <span /></label>
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" />Entregues
                        <span /></label>
                      <label className="checkbox checkbox-square checkbox-primary">
                        <input type="checkbox" />Cancelados
                        <span /></label>
                    </div>
                  </div> */}
              </div>
              {/*end::Search Form*/}
            </div>
            <div className="d-flex align-items-center">
              {/*
                {(pagamentoOnline === '' || pagamentoOnline === null) && <span class="label label-secondary label-inline mr-2">Pagamento Online - Desativado</span>}
                {pagamentoOnline === 'PENDING' && <span class="label label-warning label-inline mr-2">Pagamento Online - Pendente</span>}
                {(pagamentoOnline === 'ENABLED' || pagamentoOnline === 'ACTIVATED') && <span class="label label-success label-inline mr-2">Pagamento Online - Ativo</span>}
                */}
              <button className={"btn btn-sm"}>
                <i className={"fa fa-plug " + socketConnectionStatus} />
              </button>

              <button className={"btn btn-sm"} onClick={() => handleShow()}>
                <i className="fa fa-print" />
              </button>

              {/* {externalDeliveryActive && (
                <ExternalDeliveryButton />
              )} */}

              <DeliveryTime />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column-fluid">
          <div className="container">
            <div className="container-pedidos">
              <div
                className="column-3 kt-portlet kt-portlet--height-fluid"
                id="style-1"
              >
                <div
                  className="table-div-mesas kt-portlet__body npm"
                  id="style-1"
                >
                  <OrdersComponent />
                </div>
              </div>
              <div className="column-6 kt-portlet kt-portlet--height-fluid">
                <Details
                  pedido={orderSelected}
                  printer={selectedPrinter}
                  accept={acceptNewOrder}
                  orders={justGetOrders}
                  pedidoAutoAceiteImprimir={pedidoAutoAceiteImprimir}
                />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Contratos />

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Selecione a impressora padrão</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {selectedPrinter
            ? "Impressora selecionada: " + selectedPrinter
            : "Nenhuma Impressora selecionada"}
          <PrintersComponent />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Salvar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal size={"xl"} show={modal} onHide={() => fecharModal()}>
        <Modal.Header closeButton>
          <Modal.Title>Pagamento Online - Cadastro</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>Querido Franqueado,</p>
          <p>
            E com grande satisfação que o Aiboo está disponizando o Cadastro
            para o Pagamento Online
          </p>
          <p>Estamos fazendo isso para (pensamento) bla bla bla bla bla</p>
          <p>Dúvidas ligue: (27) 4042-1580</p>
          <p className="text-right">
            <FormControlLabel
              control={
                <Checkbox
                  checked={avisoExibir}
                  onChange={(e) => {
                    setAvisoExibir(e.target.checked);
                    getAvisoAlterar();
                  }}
                />
              }
              label="Não exibir este aviso novamente."
            />
          </p>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="primary" onClick={fecharModal}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
      {/* {ExternalDeliveryFormModal()} */}
    </>
  );
};

export default withRouter(Pedidos);
