import React, { useState, useEffect, useRef } from "react";

import Api from "../../../services/api";

import { useToasts } from "react-toast-notifications";
import Swal from "sweetalert2";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import impressoras from "../../../utils/impressora";

import { MdChatBubbleOutline } from "react-icons/md";

import ReactToPrint from "react-to-print";
import "./index.scss";

import { GoogleMap, Marker } from "@react-google-maps/api";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { useChat } from "../../../hooks/useChat";
import apiRemaster from "../../../services/apiRemaster";
import { CoverageAreasModal } from "./components/CoverageAreasModal";
import { CodeButton, CodeCancelButton } from "./components/Button";
import { generateRandomHash } from "../../../utils/randomHash";

const containerStyle = {
  width: "100%",
  height: "400px",
};

let isElectron = require("is-electron");

const Details = (props) => {
  const { addToast } = useToasts();
  const [externalDeliveryOrderStatus, initExternalDeliveryStatus] = useState(
    []
  );
  const { printer, pedido, pedidoAutoAceiteImprimir } = props;

  const [disableButtons, setDisableButtons] = useState(false);
  const [modalCancelarPedido, setModalCancelarPedido] = useState(false);

  const [showCoverageAreasModal, setShowCoverageAreasModal] = useState(false);
  const [modalUserAddressData, setModalUserAddressData] = useState("");
  const [selectedFinalAddress, setSelectedFinalAddress] = useState("");
  const [selectedFinalLatLng, setSelectedFinalLatLng] = useState("");

  const [modalFixUserAddress, setModalFixUserAddress] = useState(false);
  const [showExternalDelivery, setShowExternalDelivery] = useState(true);

  const [idPedido, setIdPedido] = useState("");
  const [observacao, setObservacao] = useState("");
  const [motivo, setMotivo] = useState("");
  const [motivationError, setMotivationError] = useState("");

  const [orderIdLoaded, setOrderIdLoaded] = useState(null);
  const [externalDeliveryLoading, setExternalDeliveryLoading] = useState(true);

  const { openChat } = useChat();

  const printRef = useRef();

  const hasErrorInCancelMotivation =
    (motivo === 0 || motivo === "0") && observacao.length <= 5;

  const deliveryCallIsAvailable =
    pedido.canUseExternalDelivery &&
    showExternalDelivery &&
    !pedido.pedidoObj.retirada_local;

  useEffect(() => {
    if (pedidoAutoAceiteImprimir?.hash_pedido) {
      updateOrderStatus(pedidoAutoAceiteImprimir.id_pedido, "PI", "auto");
      impressoras.imprimir(pedidoAutoAceiteImprimir);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pedidoAutoAceiteImprimir]);

  function printOrder(order, printerSelected) {
    updateOrderStatus(order.id_pedido, "PI");
    impressoras.imprimir(order);
  }

  if (process.env.REACT_APP_USE_ELECTRON == true) {
    console.log("Elect " + process.env.REACT_APP_USE_ELECTRON);
  } else {
    console.log("WEB2 " + process.env.REACT_APP_USE_ELECTRON);
  }

  const onLoad = React.useCallback(function callback(map) {}, []);
  const onUnmount = React.useCallback(function callback(map) {}, []);

  function handleCloseModalFixUserAddress() {
    setModalFixUserAddress(false);
  }

  function changeSelectedAddres(address, lat, lng) {
    setSelectedFinalAddress(address);
    setSelectedFinalLatLng({ lat: lat, lng: lng });
  }

  async function updateUserAddress(founded) {
    let savedData = await updateUserAddressRequest(
      modalUserAddressData.orderId,
      modalUserAddressData.orderCompleteData.endereco_id,
      founded,
      selectedFinalLatLng.lat,
      selectedFinalLatLng.lng,
      modalUserAddressData.selectedAddress,
      selectedFinalAddress
    );

    if (savedData.data.founded == true && savedData.data.success == true) {
      await updateOrderStatus(modalUserAddressData.orderId, "PL");
      handleCloseModalFixUserAddress();
      return;
    }

    handleCloseModalFixUserAddress();
    _loadExternalDeliveryRequestError();
    return;
  }

  async function onRequestExternalDelivery(code = null) {
    const id_bairro = pedido?.pedidoObj?.endereco_id_bairro;
    const orderID = pedido?.id_pedido;

    if (!id_bairro) {
      setShowCoverageAreasModal(true);
      return;
    }

    try {
      const { data } = await apiRemaster.get(`areas/${id_bairro}`);
      const { external_id } = data?.area;

      if (!!external_id) {
        await requestExternalDelivery({
          orderId: orderID,
          coverageAreaId: external_id,
          code,
        });
        return;
      }
    } catch (err) {
      console.log(err);
    }

    setShowCoverageAreasModal(true);
  }

  async function requestExternalDelivery({
    orderId,
    coverageAreaId,
    coverageAreaName,
    code,
  }) {
    setDisableButtons(true);

    try {
      let extraProps = coverageAreaName
        ? { name: coverageAreaName, external_provider: "Leve.ai" }
        : {};

      extraProps = code ? { ...extraProps, code } : extraProps;

      await apiRemaster.post("v2/delivery", {
        id_pedido: orderId,
        coverage_area_id: coverageAreaId,
        ...extraProps,
      });

      setDisableButtons(false);
      setShowCoverageAreasModal(false);
    } catch (error) {
      setDisableButtons(false);
      Swal.fire({
        title: "Atenção!",
        icon: "warning",
        html: "Bairro sem cobertura de entrega externa ou não associado.",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  async function updateUserAddressRequest(
    orderId,
    userAddressId,
    founded,
    lat,
    lng,
    selectedAddress,
    formattedAddress
  ) {
    const url = "enderecos/updateUserAddress/" + userAddressId + "/" + orderId;
    return await Api.post(url, {
      founded: founded,
      lat: lat,
      lng: lng,
      formattedAddress: formattedAddress,
      selectedAddress: JSON.stringify(selectedAddress),
    });
  }

  function _loadExternalDeliveryRequestError() {
    setShowExternalDelivery(false);

    Swal.fire({
      title: "Erro ao solicitar entrega externa!",
      icon: "error",
      html: "O endereço deste usuário não está padronizado com latitude e longitude pois não foi possível encontrar essa informação com o endereço digitado. Não poderá solicitar entrega externa para esse pedido.",
      showCloseButton: true,
      showCancelButton: false,
    });
  }

  async function updateOrderStatus(id_pedido, status, obs) {
    try {
      setDisableButtons(true);
      const externalDeliveryAvailableStatus = ["PL", "LE", "LI", "LG"];

      const isExternalDeliveryOrder =
        externalDeliveryAvailableStatus.includes(status);

      if (isExternalDeliveryOrder) {
        const response = await Api.post("pedidos/mudaStatus/" + id_pedido, {
          novo_status: status,
          obs: obs,
        });

        if (!response.data.status) {
          throw new Error();
        }
      } else {
        await apiRemaster.put(`orders/${id_pedido}`, {
          new_status: status,
          obs: obs,
        });
      }

      setDisableButtons(false);

      addToast("Status do pedido alterado", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } catch (error) {
      let errorMessage = "";
      if (error.response) {
        errorMessage = error.response.data.message;
      } else if (error.request) {
        errorMessage = "API error, try again";
      } else {
        errorMessage = error.message;
      }
      setDisableButtons(false);

      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html:
          "Ocorreu um erro ao mudar o status do pedido. Tente novamente mais tarde [" +
          errorMessage +
          "]",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    props.orders();
  }

  async function updateExternalDeliveryStatus(orderId) {
    const response = await Api.get(
      "pedidos/externalDeliveryStatusList/" + orderId
    );
    return response.data;
  }

  const ExternalDeliveryComponent = (params) => {
    if (params.externalDeliveryStatus != "FINISHED") {
      if (
        !(
          params.canUseExternalDelivery === true &&
          params.externalDeliveryStatus != null &&
          params.externalDeliveryId != null
        )
      )
        return "";
    }

    if (orderIdLoaded != params.orderId || orderIdLoaded == null) {
      setExternalDeliveryLoading(true);
      setOrderIdLoaded(params.orderId);
      updateExternalDeliveryStatus(params.orderId)
        .then((response) => {
          setExternalDeliveryLoading(false);
          initExternalDeliveryStatus(response.list);
        })
        .catch((e) => {
          setExternalDeliveryLoading(false);
          console.error("leve_ai_order_status_error", e);
        });
    }

    return (
      <>
        <hr className="port-hr " />
        <div className="row">
          <div className="col-sm-1 max-width-col-icon">
            <div className="icon-align-left icon-ajust-top">
              <i className="icon-xl fas fa-motorcycle" />
            </div>
          </div>

          <div className="col-sm-4">
            <p className="titulo-subtotal">Status (via leve.ai):</p>
          </div>

          <div className="col-sm-7 text-right">
            {externalDeliveryOrderStatus.length > 0 &&
            externalDeliveryLoading != true
              ? externalDeliveryOrderStatus.map((item, idx) => {
                  return (
                    <span key={idx}>
                      {item.date} - {item.status}
                      {item.meta_data !== null &&
                        item.meta_data !== undefined && (
                          <>
                            {item.meta_data !== undefined &&
                              item.meta_data.deliveryMan !== undefined && (
                                <>
                                  <br />
                                  <b>{item.meta_data.deliveryMan.name}</b>{" "}
                                  <br />
                                  {item.meta_data.deliveryMan.vehicle !==
                                    undefined &&
                                    item.meta_data.deliveryMan.vehicle.plate !==
                                      undefined &&
                                    item.meta_data.deliveryMan.vehicle.model !==
                                      undefined && (
                                      <span>
                                        <i class="fas fa-motorcycle"></i>{" "}
                                        {
                                          item.meta_data.deliveryMan.vehicle
                                            .model
                                        }{" "}
                                        -{" "}
                                        {
                                          item.meta_data.deliveryMan.vehicle
                                            .plate
                                        }
                                      </span>
                                    )}
                                </>
                              )}
                          </>
                        )}
                      <br />
                    </span>
                  );
                })
              : "Carregando..."}
          </div>
        </div>
      </>
    );
  };

  const ButtonActionsComponent = (params) => {
    switch (params.status) {
      case "PP":
        return (
          <>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-success mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PA")}
            >
              <i className="fas fa-print" /> Aceitar
            </button>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-danger mr-2"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      case "PA":
        return (
          <div style={{ display: "flex", gap: 8 }}>
            {pedido.pedidoObj.retirada_local ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-info"
                onClick={() =>
                  updateOrderStatus(params.id_pedido, "PICKUP_READY")
                }
              >
                Pronto para retirada
              </button>
            ) : (
              <button
                disabled={
                  disableButtons ||
                  pedido.externalDeliveryStatus === "WAITING_COLLECT" ||
                  pedido.externalDeliveryStatus === "SEARCHING_DELIVERY"
                }
                type="submit"
                className="btn btn-sm btn-info"
                onClick={() => updateOrderStatus(params.id_pedido, "PE")}
              >
                Enviar
              </button>
            )}

            {deliveryCallIsAvailable &&
            pedido.externalDeliveryStatus !== "SEARCHING_DELIVERY" &&
            pedido.externalDeliveryStatus !== "WAITING_COLLECT" &&
            pedido.externalDeliveryStatus !== "WITH_DELIVERY" ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary"
                onClick={() => onRequestExternalDelivery()}
              >
                Solicitar Motoboy
              </button>
            ) : (
              ""
            )}

            {deliveryCallIsAvailable &&
            pedido.externalDeliveryStatus !== "SEARCHING_DELIVERY" &&
            pedido.externalDeliveryStatus !== "WAITING_COLLECT" &&
            pedido.externalDeliveryStatus !== "WITH_DELIVERY" ? (
              <CodeButton
                disabled={disableButtons}
                onClick={() =>
                  onRequestExternalDelivery(generateRandomHash(5, "A-"))
                }
              />
            ) : (
              ""
            )}

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" &&
              !pedido.externalDeliveryWithCode && (
                <button
                  disabled={disableButtons}
                  type="submit"
                  className="btn btn-sm btn-danger"
                  onClick={() => cancelExternalDelivery(params.id_pedido)}
                >
                  Cancelar Solicitação
                </button>
              )}

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" &&
              !!pedido.externalDeliveryWithCode && (
                <CodeCancelButton
                  code={pedido.externalDeliveryWithCode}
                  onClick={() => cancelExternalDelivery(params.id_pedido)}
                />
              )}

            <button
              disabled={
                disableButtons ||
                pedido.externalDeliveryStatus === "WAITING_COLLECT" ||
                pedido.externalDeliveryStatus === "SEARCHING_DELIVERY"
              }
              type="submit"
              className="btn btn-sm btn-success"
              onClick={() => updateOrderStatus(params.id_pedido, "PD")}
            >
              Entregue
            </button>
            <button
              disabled={
                disableButtons ||
                pedido.externalDeliveryStatus === "WAITING_COLLECT" ||
                pedido.externalDeliveryStatus === "SEARCHING_DELIVERY"
              }
              type="submit"
              className="btn btn-sm btn-danger"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {pedido.chat_enabled && (
              <button
                className="btn btn-sm btn-secondary float-right ml-auto"
                onClick={() =>
                  openChat({
                    clientName: pedido.usuario_nome,
                    token: pedido.hash_pedido,
                    orderInfo: pedido,
                  })
                }
              >
                <MdChatBubbleOutline color="#333" size={18} />
                <span>Abrir chat</span>
              </button>
            )}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </div>
        );
      case "PI":
        return (
          <div style={{ display: "flex", gap: 8 }}>
            {pedido.pedidoObj.retirada_local ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-info"
                onClick={() =>
                  updateOrderStatus(params.id_pedido, "PICKUP_READY")
                }
              >
                Pronto para retirada
              </button>
            ) : (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-info"
                onClick={() => updateOrderStatus(params.id_pedido, "PE")}
              >
                Enviar
              </button>
            )}

            {deliveryCallIsAvailable ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary"
                onClick={() => onRequestExternalDelivery()}
              >
                Solicitar Motoboy
              </button>
            ) : (
              ""
            )}

            {deliveryCallIsAvailable ? (
              <CodeButton
                disabled={disableButtons}
                onClick={() =>
                  onRequestExternalDelivery(generateRandomHash(5, "A-"))
                }
              />
            ) : (
              ""
            )}

            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-success"
              onClick={() => updateOrderStatus(params.id_pedido, "PD")}
            >
              Entregue
            </button>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-danger"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {pedido.chat_enabled && (
              <button
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() =>
                  openChat({
                    clientName: pedido.usuario_nome,
                    token: pedido.hash_pedido,
                    orderInfo: pedido,
                  })
                }
              >
                <MdChatBubbleOutline color="#333" size={18} />
                <span>Abrir chat</span>
              </button>
            )}
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </div>
        );
      case "PL":
        return (
          <>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-info mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PE")}
            >
              Enviar
            </button>

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-danger mr-2"
                onClick={() => cancelExternalDelivery(params.id_pedido)}
              >
                Cancelar Solicitação
              </button>
            ) : (
              ""
            )}

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" ? (
              <CodeCancelButton
                code={pedido.externalDeliveryWithCode}
                onClick={() => cancelExternalDelivery(params.id_pedido)}
              />
            ) : (
              ""
            )}

            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-success mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PD")}
            >
              Entregue
            </button>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-danger mr-2"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      case "LE":
        return (
          <>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-info mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PE")}
            >
              Enviar
            </button>

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-danger mr-2"
                onClick={() => cancelExternalDelivery(params.id_pedido)}
              >
                Cancelar Solicitação
              </button>
            ) : (
              ""
            )}

            {pedido.externalDeliveryStatus == "SEARCHING_DELIVERY" ? (
              <CodeCancelButton
                code={pedido.externalDeliveryWithCode}
                onClick={() => cancelExternalDelivery(params.id_pedido)}
              />
            ) : (
              ""
            )}

            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-success mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PD")}
            >
              Entregue
            </button>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-danger mr-2"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      case "PE":
      case "PICKUP_READY":
        return (
          <>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-success mr-2"
              onClick={() => updateOrderStatus(params.id_pedido, "PD")}
            >
              Entregue
            </button>
            <button
              disabled={disableButtons}
              type="submit"
              className="btn btn-sm btn-danger mr-2"
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {pedido.chat_enabled && (
              <button
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() =>
                  openChat({
                    clientName: pedido.usuario_nome,
                    token: pedido.hash_pedido,
                    orderInfo: pedido,
                  })
                }
              >
                <MdChatBubbleOutline color="#333" size={18} />
                <span>Abrir chat</span>
              </button>
            )}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      case "PD":
        return (
          <>
            <button
              disabled={true}
              type="submit"
              className="btn btn-sm btn-danger mr-2"
              style={{ cursor: "not-allowed" }}
              onClick={() =>
                handleOpenModalCancelarPedido(params.id_pedido, "PC")
              }
            >
              Cancelar
            </button>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, 'DENUNCIAR')}>Denunciar</button> */}
            {pedido.chat_enabled && (
              <button
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() =>
                  openChat({
                    clientName: pedido.usuario_nome,
                    token: pedido.hash_pedido,
                    orderInfo: pedido,
                  })
                }
              >
                <MdChatBubbleOutline color="#333" size={18} />
                <span>Abrir chat</span>
              </button>
            )}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      case "PC":
        return (
          <>
            {/* <button type="submit" className="btn btn-sm btn-secondary" onClick={() => console.log(params.id_pedido, params.status)}>Denunciar</button> */}
            {isElectron() ? (
              <button
                disabled={disableButtons}
                type="submit"
                className="btn btn-sm btn-secondary float-right mr-2"
                onClick={() => printOrder(pedido, printer)}
              >
                Imprimir
              </button>
            ) : (
              <ReactToPrint
                trigger={() => (
                  <button
                    disabled={disableButtons}
                    className="btn btn-sm btn-secondary float-right mr-2"
                  >
                    <i className="fas fa-print" />
                    Imprimir
                  </button>
                )}
                content={() => printRef.current}
              />
            )}
          </>
        );
      default:
        return <> Nenhum pedido foi selecionado </>;
    }
  };

  function handleCloseModalCancelarPedido() {
    setModalCancelarPedido(false);
    setMotivo("");
    setObservacao("");
    setMotivationError("");
  }

  function handleOpenModalCancelarPedido(id) {
    setModalCancelarPedido(true);
    setIdPedido(id);
  }

  async function cancelExternalDelivery(orderId) {
    try {
      const response = await apiRemaster.post(
        `v2/delivery/${orderId}/cancel`,
        {}
      );
      if (response.data.message === "ORDER_CANCELED") {
        addToast("Solicitação de entrega cancelada com sucesso!", {
          appearance: "success",
          autoDismiss: true,
          autoDismissTimeout: 2000,
        });
      } else {
        Swal.fire({
          title: "Atenção!",
          icon: "info",
          html: "Problema ao cancelar solicitação de entrega.",
          showCloseButton: true,
          showCancelButton: false,
        });
      }
    } catch (err) {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Problema ao cancelar solicitação de entrega.",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
  }

  async function cancelOrder() {
    if (motivo === null || motivo === "") {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html: "Selecione um motivo para cancelamento",
        showCloseButton: true,
        showCancelButton: false,
      });

      return;
    }

    if (hasErrorInCancelMotivation) {
      setMotivationError("Descrição de motivo muito curta.");

      return;
    }

    var textosalvar = "";

    if (motivo == 0) {
      textosalvar = observacao;
    } else {
      textosalvar = motivo;
    }

    try {
      await apiRemaster.put(`orders/${pedido.id_pedido}`, {
        new_status: "PC",
        motivo_cancelamento: textosalvar,
      });

      addToast("Status do pedido alterado", {
        appearance: "success",
        autoDismiss: true,
        autoDismissTimeout: 2000,
      });
    } catch (error) {
      Swal.fire({
        title: "Atenção!",
        icon: "info",
        html:
          error.response.data.message ??
          "Ocorreu um erro ao mudar o status do pedido. Tente novamente mais tarde",
        showCloseButton: true,
        showCancelButton: false,
      });
    }
    props.orders();
    handleCloseModalCancelarPedido();
  }

  function changePreSet(e) {
    setMotivo(e.target.value);
  }

  /*function handleMotivo() {
       if (motivo === 0  || motivo==='0') {
           return observacao
       }
                    return motivo
    }*/

  return (
    <>
      <div className="cabecalho" ref={printRef}>
        <p
          className="content"
          dangerouslySetInnerHTML={{ __html: pedido.pedido }}
        ></p>
      </div>

      <div role="toolbar" className="btn-toolbar botoes-acoes-area">
        <ButtonActionsComponent
          status={pedido.status}
          completeObject={pedido.pedidoObj}
          id_pedido={pedido.id_pedido}
        />
      </div>
      <hr className="port-hr mb-0" />

      <div className="pedido-area-center">
        {/*<div className="pedido-area" id="style-1">
                    <ReactMarkdown source={pedido.pedido} escapeHtml={false}/>
                </div>*/}
        {pedido.pedido && (
          <div className="pedido-area-center">
            <div className="pedido-area" id="style-1">
              <div className="row">
                <div className="col-sm-1 max-width-col-icon">
                  <div className="icon-align-left">
                    <i className="icon-xl fas fa-user" />
                  </div>
                </div>
                <div className="col-sm-5">
                  <p>
                    <strong>
                      {pedido.pedidoObj.usuario_nome}
                      <span className="label label-rounded label-success ml-2">
                        {pedido.pedidoObj.usuario_compras}
                      </span>
                    </strong>{" "}
                    - {pedido.pedidoObj.hash_pedido}
                    <br />
                    {pedido.pedidoObj.usuario_compras === "1" && (
                      <>
                        <span
                          className="cliente-novo-loja"
                          data-toggle="tooltip"
                          title=""
                          data-original-title="Cliente novo! Primeira compra dele em sua loja. Não deixe de dar aquela caprichada ;)"
                        >
                          <i className="fas fa-trophy"></i> Cliente novo na
                          loja!
                        </span>
                        <br />
                      </>
                    )}
                    {pedido.pedidoObj.usuario_confirmado === true && (
                      <i
                        className="flaticon2-correct text-success"
                        data-toggle="tooltip"
                        data-original-title="O telefone deste usuário foi verificado e confirmado pelo Aiboo!"
                      />
                    )}
                    {pedido.pedidoObj.usuario_telefone_formatado}
                  </p>
                </div>
                <div className="col-sm-1 max-width-col-icon">
                  <div className="icon-align-left">
                    <i className="icon-xl fas fa-motorcycle" />
                  </div>
                </div>
                <div className="col-sm-5">
                  {pedido.pedidoObj.retirada_local === false && (
                    <p>{pedido.pedidoObj.endereco_completo}</p>
                  )}
                  {pedido.pedidoObj.retirada_local === true && (
                    <p>Retirada no local</p>
                  )}
                </div>
                <hr className="port-hr" />
              </div>
              {pedido.pedidoObj.itens.map((item, index) => {
                return (
                  <div key={index} className="row row-pedido">
                    <div className="col-sm-1 max-width-col-icon">
                      <div className="icon-align-left">
                        <i className="icon-xl fas fa-bookmark" />
                      </div>
                    </div>
                    <div className="col-sm-9">
                      <p className="titulo-produto">
                        {item.quantidade} unid. {item.nome}
                      </p>
                      {item.linhas.map((linha, index) => {
                        return (
                          <p key={index} className="complemento">
                            {linha}
                          </p>
                        );
                      })}
                    </div>
                    <div className="col-sm-2">
                      <p className="preco float-right">
                        <span className="qtd-preco">{item.quantidade}x</span>
                        <span className="cifrao">R$</span>
                        {item.valor_unitario}
                      </p>
                    </div>
                  </div>
                );
              })}

              {pedido.pedidoObj.observacoes && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: pedido.pedidoObj.observacoes,
                  }}
                />
              )}

              <hr className="port-hr" />
              <div className="row">
                <div className="col-sm-1 max-width-col-icon">
                  <div className="icon-align-left icon-ajust-top">
                    <i className="icon-xl fas fa-money-bill-alt" />
                  </div>
                </div>
                {pedido.pedidoObj.dados_pagamento.map((val, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-sm-9">
                        <p className="titulo-subtotal">{val.label}</p>
                      </div>
                      <div className="col-sm-2">
                        <p className="preco float-right">{val.value}</p>
                      </div>
                      <div className="col-sm-1 max-width-col-icon"></div>
                      <div className="col-sm-11">
                        <div className="separador-valor" />
                      </div>
                      <div className="col-sm-1 max-width-col-icon"></div>
                    </React.Fragment>
                  );
                })}
                <div className="col-sm-9">
                  <p className="titulo-total">TOTAL:</p>
                </div>
                <div className="col-sm-2">
                  <p className="preco-total float-right">
                    <span className="cifrao">R$</span>
                    {pedido.pedidoObj.cobrar_cliente}
                  </p>
                </div>
              </div>
              <hr className="port-hr" />
              <div className="row">
                <div className="col-sm-1 max-width-col-icon">
                  <div className="icon-align-left icon-ajust-top">
                    <i className="icon-xl fas fa-wallet" />
                  </div>
                </div>
                <div className="col-sm-5">
                  <p className="titulo-subtotal">Forma de Pagamento:</p>
                </div>
                <div className="col-sm-6 text-right">
                  {pedido.pedidoObj.forma_pagamento}
                </div>
              </div>
              <hr className="port-hr" />
              <div className="row">
                <div className="col-sm-1 max-width-col-icon">
                  <div className="icon-align-left icon-ajust-top">
                    <i className="icon-xl fas fa-clock" />
                  </div>
                </div>
                {pedido.pedidoObj.andamentos.map((val, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="col-sm-5">
                        <p className="titulo-subtotal">{val.label}</p>
                      </div>
                      <div className="col-sm-6 text-right">{val.value}</div>
                      <div className="col-sm-1 max-width-col-icon"></div>
                    </React.Fragment>
                  );
                })}
              </div>

              {/*
               */}
              <ExternalDeliveryComponent
                orderId={pedido.id_pedido}
                status={pedido.status}
                canUseExternalDelivery={pedido.canUseExternalDelivery}
                externalDeliveryStatus={pedido.externalDeliveryStatus}
                externalDeliveryId={pedido.externalDeliveryId}
              />
              <div className="espaco-pedido" />
            </div>
          </div>
        )}
      </div>
      <CoverageAreasModal
        orderId={pedido.id_pedido}
        show={showCoverageAreasModal}
        handleClose={() => setShowCoverageAreasModal(false)}
        handleCallDeliveryMan={requestExternalDelivery}
      />
      <Modal
        show={modalCancelarPedido}
        onHide={() => handleCloseModalCancelarPedido()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Cancelar Pedido</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            className="alert alert-custom alert-outline-2x alert-outline-warning fade show mb-5"
            role="alert"
          >
            <div className="alert-icon">
              <i className="flaticon-warning" />
            </div>
            <div className="alert-text">
              OBS: O cliente receberá o motivo do cancelamento.
            </div>
            <div className="alert-close">
              <button
                type="button"
                className="close"
                onClick={() => handleCloseModalCancelarPedido()}
              >
                <span aria-hidden="true">
                  <i className="ki ki-close" />
                </span>
              </button>
            </div>
          </div>
          <div className="form-group">
            <label>Motivo:</label>
            <select onChange={(e) => changePreSet(e)} className="form-control">
              <option>SELECIONE</option>
              <option value={"Cliente indisponível para contato"}>
                Cliente indisponível para contato
              </option>
              <option value={"Cliente pediu um item que não estava disponível"}>
                Cliente pediu um item que não estava disponível
              </option>
              <option value={"Cliente pediu para cancelar o pedido"}>
                Cliente pediu para cancelar o pedido
              </option>
              <option value={0}>Outro motivo...</option>
            </select>
          </div>
          {(motivo === 0 || motivo === "0") && (
            <div className="form-group">
              <label>Descreva o motivo:</label>
              <textarea
                className="form-control form-control-lg"
                type="text"
                onChange={(e) => setObservacao(e.target.value)}
              />

              {hasErrorInCancelMotivation && motivationError && (
                <span className="d-block mt-4 text-red text-danger">
                  {motivationError}
                </span>
              )}
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => handleCloseModalCancelarPedido()}
          >
            Fechar
          </Button>
          <Button variant="primary" onClick={() => cancelOrder()}>
            Cancelar Pedido
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={modalFixUserAddress}
        onHide={() => handleCloseModalFixUserAddress()}
      >
        <Modal.Header closeButton>
          <Modal.Title>Ajustar endereço de entrega</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="texto-msg-top">
            Endereço informado: {modalUserAddressData.enderecoDigitado}
          </p>
          <ReactGoogleAutocomplete
            apiKey={`AIzaSyBAxkbESjs2USUetjOqOtthSIKftuIrEGs`}
            onPlaceSelected={(place) => {
              console.log(place);

              if (
                place !== null &&
                place !== undefined &&
                place.formatted_address !== undefined
              ) {
                var lat = place?.geometry?.location?.lat(),
                  lng = place?.geometry?.location?.lng();

                changeSelectedAddres(place.formatted_address, lat, lng);
              }
            }}
            options={{
              types: ["geocode", "establishment"],
              fields: ["formatted_address", "geometry", "geometry.location"],
            }}
            defaultValue={modalUserAddressData.enderecoDigitado}
            placeholder={"Digite o endereço correto aqui"}
            language={"pt-BR"}
            style={{
              width: "100%",
              padding: "16.5px 14px",
              height: "50px",
              fontSize: "16px",
              borderColor: "red",
              margin: "0px 0px 20px 0px",
            }}
          />
          <br /> <br />
          <div style={{ height: "300", width: "100%" }}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={selectedFinalLatLng}
              zoom={18}
              onLoad={onLoad}
              onUnmount={onUnmount}
            >
              <Marker
                onLoad={onLoad}
                position={selectedFinalLatLng}
                draggable={true}
                onDragEnd={(event) => {
                  console.log(event.latLng.lat(), event.latLng.lng(), 1);
                }}
              />
            </GoogleMap>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            className="btn btn-sm btn-success mr-2"
            variant="secondary"
            onClick={() => updateUserAddress(true)}
          >
            Encontrei o endereço
          </Button>
          <Button
            className="btn btn-sm btn-danger mr-2"
            variant="secondary"
            onClick={() => updateUserAddress(false)}
          >
            Não encontrei o endereço
          </Button>
          <Button
            variant="secondary"
            onClick={() => handleCloseModalFixUserAddress()}
          >
            Fechar Solicitação
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Details;
